<template>
  <div>
    <v-container>
      <v-overlay :value="gettingProvider" opacity="0.8">
        <div>
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
      </v-overlay>
      <v-btn text color="accent" @click="$router.go(-1)" class="mb-2">
        <v-icon left> mdi-arrow-left-thick </v-icon>
        Back
      </v-btn>
      <v-row>
        <v-col cols="12" md="8">
          <h1 class="text-capitalize">{{ unSlugify(billType) }}</h1>
          <p class="text-subtitle-1 font-weight-bold">
            Got questions? Call or Whatsapp {{ siteData.phone }}
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field v-model="search" label="Search services"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="searchProviders.length == 0 && !gettingProvider">
          <v-card>
            <v-container>
              <v-card-title class="text-center"
                >Service not Provided at the moment</v-card-title
              >
              <v-card-subtitle>
                <b>"{{ search }}"</b> is not available at the
                moment</v-card-subtitle
              >
            </v-container>
          </v-card>
        </v-col>
        <v-col
          v-else
          v-for="provider in searchProviders"
          :key="provider._id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              ripple
              :to="`/user/products/bills/${billType}/${slugify(
                provider.title
              )}?${getParams(provider)}`"
              :elevation="hover ? '8' : '2'"
              rounded="lg"
              class="pa-5 text-center"
            >
              <v-img contain :src="provider.image" height="115"></v-img>
              <v-card-title class="text-center mx-auto">
                <span
                  style="font-size: 16px"
                  class="mx-auto font-bold text-uppercase"
                  >{{ provider.title }}</span
                >
              </v-card-title>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import billApi from '../../../../api/users/bill'

import { unSlugify, slugify } from '../../../../utils/helpers'

export default {
  name: 'BillInformation',
  data: () => ({
    unSlugify,
    slugify,
    billType: '',
    search: '',
    gettingProvider: false,
    providers: []
  }),
  created() {
    this.billType = this.$route.params.name
  },
  computed: {
    ...mapState({
      siteData: (state) => state.user.siteSettings
    }),
    searchProviders() {
      if (this.search) {
        return this.providers.filter((provider) =>
          provider.title
            .toString()
            .toLowerCase()
            .includes(this.search.toLowerCase())
        )
      }
      return this.providers
    }
  },
  mounted() {
    if (this.billType === 'airtime' || this.billType === 'convert-airtime') {
      this.getAirtimeProvider()
    } else if (this.billType === 'data') {
      this.getDataProvider()
    } else if (this.billType === 'cable-tv') {
      this.getCableProvider()
    } else if (this.billType === 'bettings-top-up') {
      this.getBettingProviders()
    } else if (this.billType === 'internet-data') {
      this.getDataProvider()
    } else if (this.billType === 'electricity') {
      this.getElectricityProvider()
    }
  },
  methods: {
    async getBettingProviders() {
      this.gettingProvider = true
      try {
        const req = await billApi.billApi().betting.getProviders({
          limit: 0
        })
        if (req.error) {
          this.$store.dispatch('alert', {
            message:
              req.errorMessage.message ||
              `${req.internalError.message}, please try again`,
            status: true,
            error: true
          })
        }
        this.providers = req.data.data.map((provider) => ({
          ...provider,
          image: provider.providerLogoUrl,
          title: provider.provider
        }))
        this.gettingProvider = false
      } catch {
        console.log('error')
      }
    },
    async getCableProvider() {
      this.gettingProvider = true
      try {
        const req = await billApi.billApi().cable.getProviders({
          limit: 0
        })
        if (req.error) {
          this.$store.dispatch('alert', {
            message:
              req.errorMessage.message ||
              `${req.internalError.message}, please try again`,
            status: true,
            error: true
          })
        }
        this.providers = req.data.data.map((provider) => ({
          ...provider,
          image: this.prependUrlIfNot(provider.providerLogoUrl),
          title: provider.provider
        }))
        this.gettingProvider = false
      } catch {
        console.log('error')
      }
    },
    async getAirtimeProvider() {
      this.gettingProvider = true
      try {
        const req = await billApi.billApi().airtime.getProviders({
          limit: 0
        })
        if (req.error) {
          this.$store.dispatch('alert', {
            message:
              req.errorMessage.message ||
              `${req.internalError.message}, please try again`,
            status: true,
            error: true
          })
        }
        this.providers = req.data.data.map((provider) => ({
          ...provider,
          image: this.prependUrlIfNot(provider.providerLogoUrl),
          title: provider.provider
        }))
        this.gettingProvider = false
      } catch {
        console.log('error')
      }
    },
    async getDataProvider() {
      this.gettingProvider = true
      try {
        const req = await billApi.billApi().data.getProviders({
          limit: 0
        })
        if (req.error) {
          this.$store.dispatch('alert', {
            message:
              req.errorMessage.message ||
              `${req.internalError.message}, please try again`,
            status: true,
            error: true
          })
        }
        this.providers = req.data.data.map((provider) => ({
          ...provider,
          image: this.prependUrlIfNot(provider.providerLogoUrl),
          title: provider.provider
        }))
        this.gettingProvider = false
      } catch {
        console.log('error')
      }
    },
    async getElectricityProvider() {
      this.gettingProvider = true
      try {
        const req = await billApi.billApi().electricity.getProviders({
          limit: 0
        })
        if (req.error) {
          this.$store.dispatch('alert', {
            message:
              req.errorMessage.message ||
              `${req.internalError.message}, please try again`,
            status: true,
            error: true
          })
        }
        this.providers = req.data.data.map((provider) => ({
          ...provider,
          image: this.prependUrlIfNot(provider.providerLogoUrl),
          title: provider.provider
        }))
        this.gettingProvider = false
      } catch {
        console.log('error')
      }
    },
    prependUrlIfNot(link) {
      if (link.includes('https://giftbills.com/')) {
        return link
      } else {
        return `https://giftbills.com/${link}`
      }
    },
    getParams(provider) {
      return new URLSearchParams(provider).toString()
    }
  }
}
</script>

<style></style>
